
import Vue from 'vue';
import {
  AdvertiserAgencyQuery,
  AdvertiserSearchPayload,
  AgencySearchPayload,
} from '../../store/modules/scheduledreports/types';
import analytics from '../../mixins/analytics';
import util from '../../util';
export default Vue.extend({
  mixins: [analytics],
  components: {
    ResultsList: () => import('./resultsList.vue'),
  },
  data(): {
    deleteMsg: string | null;
  } {
    return {
      deleteMsg: null,
    };
  },
  created(): void {
    if (this.$route.query?.advertiser || this.$route.query?.agency) {
      this.getReports();
    }
  },
  methods: {
    goBack(): void {
      this.$router.push({ name: 'home', query: { recent: 'true' } });
    },
    addNew(): void {
      const query: AdvertiserAgencyQuery = {};
      if (this.$route.query.advertiser) query.advertiser = this.$route.query.advertiser;
      if (this.$route.query.agency) query.agency = this.$route.query.agency;
      this.$router.push({ name: 'scheduledreport', query });
    },
    updateResults(params: AdvertiserSearchPayload | AgencySearchPayload): void {
      this.getReports(params);
    },
    getReports(payloadExtras: AdvertiserSearchPayload | AgencySearchPayload): void {
      let apiAddress;
      if (this.$route.query?.advertiser) {
        apiAddress = 'getAdvertiserReports';
      } else if (this.$route.query?.agency) {
        apiAddress = 'getAgencyReports';
      } else {
        // eslint-disable-next-line no-console
        console.error('no advertiser or agency id');
      }
      const payload = { ...this.genericPayload, ...payloadExtras };
      this.$store.dispatch(`scheduledReports/${apiAddress}`, payload);
    },
    deleteReport({ reportId, reportName, advertiser, search }): void {
      this.$store
        .dispatch(`scheduledReports/deleteReport`, {
          reportId,
          requestedBy: this.$store.getters.user.email,
        })
        .then(res => {
          this.deleteMsg = res;
          // backend needs time to process.
          setTimeout(() => this.updateResults({ search: search, offset: 0 }), 2000);
          setTimeout(() => (this.deleteMsg = null), 5000);
          this.analyticDeleteReportTrack(
            this.trackValue.EVENT_MANAGE_REPORTS,
            this.trackValue.TRACK_REPORT_DELETE,
            reportId,
            this.$store.getters.user.email,
            reportName,
            advertiser,
          );
        })
        .catch(() => {
          this.deleteMsg = 'Error deleting this report';
          setTimeout(() => (this.deleteMsg = null), 5000);
        });
    },
  },
  computed: {
    error(): string {
      return this.$store.state?.scheduledReports?.error;
    },
    loading(): boolean {
      return this.$store.state?.scheduledReports?.loading;
    },
    reports(): Array<object> {
      return this.$store.state?.scheduledReports?.reports;
    },
    genericPayload(): AdvertiserSearchPayload | AgencySearchPayload | null {
      if (this.$route.query?.advertiser) {
        const payload: AdvertiserSearchPayload = {
          advertiserId: this.$route.query.advertiser,
        };
        return payload;
      } else if (this.$route.query?.agency) {
        const payload: AgencySearchPayload = {
          agency: this.$route.query?.agency,
        };
        return payload;
      } else {
        return null;
      }
    },
  },
});
